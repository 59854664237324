import React, { useEffect, useState } from "react";

function WhyUs() {
  const [showHeading, setShowHeading] = useState(false);
  const [showCards, setShowCards] = useState(false);
  const [showHeader, setShowHeader] = useState(false); // New state for header
  let lastScrollTop = 0; // Keep track of the last scroll position

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      // Check if scrolling down or up
      if (scrollTop > lastScrollTop && scrollTop > 100) {
        setShowCards(true); // Show cards when scrolling down
        setShowHeader(true); // Show header when scrolling down
        setShowHeading(true);
      } else if (scrollTop < lastScrollTop) {
        setShowCards(false); // Hide cards when scrolling up
        setShowHeader(false); // Hide header when scrolling up
        setShowHeading(false);
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For mobile or negative scrolling
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up the event listener
    };
  }, []);

  // Inline styles
  const styles = {
    whyUsSection: {
      backgroundColor: "#f7f7f7",
      textAlign: "center",
      padding: "40px 20px",
    },
    headingStyle: {
      fontSize: "50px",
      marginBottom: "10px",
      opacity: showHeading ? 1 : 0, // Transition between visible and hidden
      transition: "opacity 0.6s ease-in-out", // Smooth transition
    },

    highlightTextStyle: {
      color: "#f44336", // red color for 'The Real Magic'
    },
    headerText: {
      fontSize: "22px",
      fontWeight: "bold",
      marginBottom: "20px",
      opacity: showHeader ? 1 : 0,
      transition: "opacity 0.5s ease, transform 0.5s ease",
      transform: showHeader ? "translateY(0)" : "translateY(-20px)", // Add transform for scrolling effect
    },
    mainContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    leftCards: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      opacity: showCards ? 1 : 0,
      transition: "opacity 0.5s ease, transform 0.5s ease",
      transform: showCards ? "translateY(0)" : "translateY(50px)",
    },
    rightCards: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      opacity: showCards ? 1 : 0,
      transition: "opacity 0.5s ease, transform 0.5s ease",
      transform: showCards ? "translateY(0)" : "translateY(50px)",
    },
    card: {
      backgroundColor: "#e74c3c",
      padding: "20px",
      color: "white",
      textAlign: "left",
      borderRadius: "8px",
      position: "relative",
      width: "400px", // Set a fixed width for consistency
      height: "100px", // Set a fixed height for consistency
    },
    cardTitle: {
      fontSize: "1.2rem",
      marginBottom: "10px",
    },
    cardDescription: {
      fontSize: "0.9rem",
      height: "60px", // Ensure consistent height for description
      overflow: "hidden",
    },
    cardIcon: {
      position: "absolute",
      top: "10px",
      right: "10px",
      fontSize: "2rem",
    },
    centerImage: {
      width: "390px",
      height: "auto",
      borderRadius: "10px",
    },
  };

  return (
    <div style={styles.whyUsSection}>
      <hr
        style={{
          width: "200px",
          border: "none",
          borderTop: "15px solid red", // Increase thickness here
          margin: "10px auto 10px auto", // Center the line
          fontWeight: "bold",
        }}
      />
      <h2 style={styles.headingStyle}>
        Why <span style={styles.highlightTextStyle}>Choose Us?</span>
      </h2>
      <p
        style={{
          fontSize: "20px",
        }}
      >
        We're obsessed with perfection, leaving no nook or cranny untouched.
        Explore the reasons why you should trust expert{" "}
        <span
          style={{
            fontSize: "22px",
            fontWeight: "700",
          }}
        >
          car detailing services{" "}
        </span>
        by us!
      </p>
      <div style={styles.headerText}>
        <h1>
          You like it{" "}
          <span
            style={{
              color: "red",
            }}
          >
            Clean?
          </span>
          <br />
          We like it{" "}
          <span
            style={{
              color: "red",
            }}
          >
            Dirty!
          </span>
        </h1>
      </div>
      <div style={styles.mainContent}>
        <div style={styles.leftCards}>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Commitment:</h3>
            <p style={styles.cardDescription}>
              At The Detailing Mafia, we aim for 3 A’s. Accessible, Affordable,
              and advanced car care services.
            </p>
            <i className="fas fa-handshake" style={styles.cardIcon}></i>
          </div>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Quality:</h3>
            <p style={styles.cardDescription}>
              We provide premium car care services without compromising on
              quality.
            </p>
            <i className="fas fa-award" style={styles.cardIcon}></i>
          </div>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Commitment:</h3>
            <p style={styles.cardDescription}>
              At The Detailing Mafia, we aim for 3 A’s. Accessible, Affordable,
              and advanced car care services.
            </p>
            <i className="fas fa-handshake" style={styles.cardIcon}></i>
          </div>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Quality:</h3>
            <p style={styles.cardDescription}>
              We provide premium car care services without compromising on
              quality.
            </p>
            <i className="fas fa-award" style={styles.cardIcon}></i>
          </div>
        </div>
        <div>
          <img
            src="https://images.unsplash.com/photo-1500530855697-b586d89ba3ee?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Y2Fyc3xlbnwwfHwwfHx8MA%3D%3D"
            alt="Car"
            style={styles.centerImage}
          />
        </div>
        <div style={styles.rightCards}>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Equipment:</h3>
            <p style={styles.cardDescription}>
              We do have an extra edge whilst complying with the industry's
              standards.
            </p>
            <i className="fas fa-tools" style={styles.cardIcon}></i>
          </div>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>The Gang:</h3>
            <p style={styles.cardDescription}>
              Our team is completely trained and professional to the work.
            </p>
            <i className="fas fa-users" style={styles.cardIcon}></i>
          </div>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Equipment:</h3>
            <p style={styles.cardDescription}>
              We do have an extra edge whilst complying with the industry's
              standards.
            </p>
            <i className="fas fa-tools" style={styles.cardIcon}></i>
          </div>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>The Gang:</h3>
            <p style={styles.cardDescription}>
              Our team is completely trained and professional to the work.
            </p>
            <i className="fas fa-users" style={styles.cardIcon}></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
