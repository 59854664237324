import React, { useEffect, useState } from "react";
import Footer from "../components/MainPage/Footer";
import MainHeader from "../components/MainHeader";

function AboutUs() {
  const [showAnimatedContent, setShowAnimatedContent] = useState(false);
  const [showAnimatedBenifites, setShowAnimatedBenifites] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowAnimatedBenifites(scrollY > 100);
      setShowAnimatedContent(scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <MainHeader />
      {/* Banner Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#000",
          color: "white",
          padding: "20px",
          top: 0,
          zIndex: 1000,
        }}
      >
        <div>
          <h1
            style={{
              fontSize: "2.5rem",
              margin: "0",
              fontFamily: "Arial, sans-serif",
            }}
          >
            Live The Dream With
          </h1>
          <h2
            style={{
              fontSize: "2rem",
              color: "#ff3a00",
              marginTop: "10px",
              fontFamily: "Arial, sans-serif",
            }}
          >
            Manmachine Car Care
          </h2>
        </div>
        <div>
          <img
            src="https://images.unsplash.com/photo-1485445636332-cbe2d86716dd?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGNhcnN8ZW58MHx8MHx8fDA%3D"
            alt="Car"
            style={{ maxHeight: "300px" }}
          />
        </div>
      </div>
      <div
        style={{
          fontFamily: "fantasy",
          fontSize: "60px",
          fontWeight: "700",
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        ABOUT{" "}
        <span
          style={{
            color: "red",
          }}
        >
          US
        </span>
      </div>
      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingInline: "50px",
          position: "relative",
          marginTop: "-20px",
        }}
      >
        <div
          style={{
            flex: 1,
            opacity: showAnimatedContent ? 1 : 0,
            transition: "opacity 0.7s ease",
          }}
        >
          <img
            src="https://cdn.pixabay.com/photo/2017/06/03/08/11/car-2368193_1280.png"
            alt="Car"
            style={{
              width: 550,
              height: 550,
            }}
          />
        </div>
        <div
          style={{
            flex: 1,
            opacity: showAnimatedContent ? 1 : 0,
            transition: "opacity 0.7s ease",
          }}
        >
          <p
            style={{
              fontFamily: "sans-serif",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: 1.7,
            }}
          >
            Being the market leader in Detailing Cars, our vision rightly
            presents the budding soul of Manmachine. As a 37-years old company,
            we have evolved into a unified player across different sectors,
            mainly Automotive Detailing Services. Our commitment to exponential
            and innovation-led growth has forced us to achieve leadership in our
            businesses. We prove our belief in our business with company owned
            outlets and 37 years of rich and quality experience. Manmachine’s
            portfolio touches the lives of thousands of Indians on a regular
            basis across wide cleaning and detailing spectrums. Our visionary
            CEO of The Detailing Mafia - Mr. Kunal Sethi, continued the legacy
            of "Manmachine Group, ESTD. 1987". The Detailing Mafia has reached
            unprecedented heights through his Efforts. Now The Detailing Mafia
            has 180+ Franchises in pan India. Manmachine Car Care is focused on
            creating platforms that announce the booming of the Detailing
            industry in the Indian market whilst creating endless opportunities
            and realizing the true potential. We strive harder to bring, the
            best services to form awe-inspiring results for any car owner and
            enthusiasts can be proud of.
          </p>
        </div>
      </section>

      {/* Text Above Image Section */}
      <div
        style={{ position: "relative", textAlign: "center", marginTop: "50px" }}
      >
        <img
          src="https://images.unsplash.com/photo-1517841905240-472988babdf9?w=1000&fit=crop&q=80"
          alt="Obsessed With Quality"
          style={{ width: "100%", height: "400px", objectFit: "cover" }}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            padding: "20px",
            color: "white",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: "2.5rem", marginBottom: "10px" }}>
            Obsessed With Quality
          </h1>
          <p style={{ fontSize: "1.2rem", lineHeight: "1.6" }}>
            MANMACHINE CAR CARE The Paragon of Detailing industry brings you a
            wide range of car care products from the best companies across the
            Globe. We closely work with end companies that focus on
            manufacturing the products, without compromising the quality.
            Blending the experience with innovation and technology, our products
            ensure the ultimate presentation and superior protection.
          </p>
        </div>
      </div>

      {/* New Section Below */}
      <div
        style={{
          textAlign: "center",
          marginTop: "50px",
          backgroundImage:
            "url('https://images.unsplash.com/photo-1470723710355-95304d8aece4?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGNhcnN8ZW58MHx8MHx8fDA%3D')", // Your background image URL
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "50px 0",
          color: "white",
          position: "relative",
        }}
      >
        {/* Overlay */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Light black overlay (50% opacity)
            zIndex: 1,
          }}
        ></div>

        {/* Content */}
        <div style={{ position: "relative", zIndex: 2 }}>
          <div
            style={{
              width: "400px",
              height: "10px",
              backgroundColor: "#ff3a00", // Red color
              margin: "0 auto -20px auto",
            }}
          ></div>
          <h1 style={{ fontSize: "50px", fontWeight: "700" }}>
            Why The Detailing Mafia?
          </h1>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              paddingInline: "50px",
            }}
          >
            {/* First Column */}
            <div style={{ flex: 1, textAlign: "left", padding: "20px" }}>
              <div
                style={{
                  width: "400px",
                  height: "2px",
                  backgroundColor: "gray", // Red color
                  margin: "0 auto 5px auto",
                }}
              ></div>
              <i
                className="fas fa-shield-alt"
                style={{
                  fontSize: "40px",
                  color: "#ff3a00",
                  marginBottom: "10px",
                }}
              ></i>
              <h3 style={{ color: "#ff3a00", fontSize: "20px" }}>Pedigree</h3>
              <p
                style={{
                  fontSize: "16px",
                  lineHeight: 1.5,
                }}
              >
                With 37 years of experience, our ISO 9001:2008 compliant firm
                specializes in Automobile Detailing, making it our core
                business.
              </p>
            </div>
            {/* Second Column */}
            <div style={{ flex: 1, textAlign: "left", padding: "20px" }}>
              <div
                style={{
                  width: "400px",
                  height: "2px",
                  backgroundColor: "gray", // Red color
                  margin: "0 auto 5px auto",
                }}
              ></div>
              <i
                className="fas fa-car"
                style={{
                  fontSize: "40px",
                  color: "#ff3a00",
                  marginBottom: "10px",
                }}
              ></i>
              <h3 style={{ color: "#ff3a00", fontSize: "20px" }}>
                Specialization Detailing
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  lineHeight: 1.5,
                }}
              >
                We use world-class and imported detailing products on your
                vehicle, specially designed to get the right look and protection
                for your beloved machine!
              </p>
            </div>
            {/* Third Column */}
            <div style={{ flex: 1, textAlign: "left", padding: "20px" }}>
              <div
                style={{
                  width: "400px",
                  height: "2px",
                  backgroundColor: "gray", // Red color
                  margin: "0 auto 5px auto",
                }}
              ></div>
              <i
                className="fas fa-handshake"
                style={{
                  fontSize: "40px",
                  color: "#ff3a00",
                  marginBottom: "10px",
                }}
              ></i>
              <h3 style={{ color: "#ff3a00", fontSize: "20px" }}>
                Exclusivity
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  lineHeight: 1.5,
                }}
              >
                Manmachine is the exclusive distributor for products used by
                “The Detailing Mafia”, approved by top companies like Mahindra,
                TATA Motors, BMW, Jaguar, and Land Rover.
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              paddingInline: "50px",
            }}
          >
            {/* Fourth Column */}
            <div style={{ flex: 1, textAlign: "left", padding: "20px" }}>
              <div
                style={{
                  width: "400px",
                  height: "2px",
                  backgroundColor: "gray", // Red color
                  margin: "0 auto 5px auto",
                }}
              ></div>
              <i
                className="fas fa-shield-alt"
                style={{
                  fontSize: "40px",
                  color: "#ff3a00",
                  marginBottom: "10px",
                }}
              ></i>
              <h3 style={{ color: "#ff3a00", fontSize: "20px" }}>
                Highly Skilled Staff
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  lineHeight: 1.5,
                }}
              >
                Our Staff is provided with periodic training on new and improved
                ways to treat and protect vehicles and deliver the
                best-detailing results.
              </p>
            </div>
            {/* Fifth Column */}
            <div style={{ flex: 1, textAlign: "left", padding: "20px" }}>
              <div
                style={{
                  width: "400px",
                  height: "2px",
                  backgroundColor: "gray", // Red color
                  margin: "0 auto 5px auto",
                }}
              ></div>
              <i
                className="fas fa-car"
                style={{
                  fontSize: "40px",
                  color: "#ff3a00",
                  marginBottom: "10px",
                }}
              ></i>
              <h3 style={{ color: "#ff3a00", fontSize: "20px" }}>
                World Class Products
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  lineHeight: 1.5,
                }}
              >
                We Import International standards of Automobile detailing to the
                Indian automotive market. Products like #Labocosmetica and
                Clarity Coat help to deliver the highest standards of Detailing.
              </p>
            </div>
            {/* Sixth Column */}
            <div style={{ flex: 1, textAlign: "left", padding: "20px" }}>
              <div
                style={{
                  width: "400px",
                  height: "2px",
                  backgroundColor: "gray", // Red color
                  margin: "0 auto 5px auto",
                }}
              ></div>
              <i
                className="fas fa-handshake"
                style={{
                  fontSize: "40px",
                  color: "#ff3a00",
                  marginBottom: "10px",
                }}
              ></i>
              <h3 style={{ color: "#ff3a00", fontSize: "20px" }}>
                Large Portfolio Of Service
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  lineHeight: 1.5,
                }}
              >
                We boast the most extensive portfolio of detailing services that
                can be provided under a single roof. Be it Ceramic Coating,
                Denting Painting, Paint Protection Film, Peel-able paint, and
                much more.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* key benifits */}

      <section
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingInline: "50px",
          position: "relative",
          marginTop: "-20px",
        }}
      >
        <div
          style={{
            flex: 1,
            opacity: showAnimatedBenifites ? 1 : 0,
            transition: "opacity 0.7s ease",
          }}
        >
          <div
            style={{
              width: "300px",
              height: "10px",
              backgroundColor: "#ff3a00", // Red color
              margin: "0 auto -20px auto",
            }}
          ></div>
          <h1
            style={{
              fontFamily: "sans-serif",
              textAlign: "center",
              fontSize: "50px",
              fontWeight: "700",
            }}
          >
            Key Benefits
          </h1>
          <p
            style={{
              fontFamily: "sans-serif",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: 1.7,
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              The Detailing Mafia
            </span>{" "}
            believes in perfection, and this is clearly visible in our services.
            We value both your car and your hard earned money. With our services
            we are giving a new definition to automotive detailing.We imbibe a
            wide range of perks that provide the necessary impulse to choose our
            exclusive car care services.
          </p>
        </div>
        <div
          style={{
            flex: 1,
            opacity: showAnimatedBenifites ? 1 : 0,
            transition: "opacity 0.7s ease",
            display: "flex", // Added display flex
            alignItems: "center", // Centering vertically
            justifyContent: "center", // Centering horizontally
            textAlign: "center",
          }}
        >
          <img
            src="https://cdn.pixabay.com/photo/2012/05/29/00/43/car-49278_1280.jpg"
            alt="Car"
            style={{
              width: 700,
              height: 450,
            }}
          />
        </div>
      </section>

      {/* Cards section */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-around", // Spread the cards evenly
          margin: "20px 0",
          gap: "30px",
          padding: "0 20px",
        }}
      >
        {/* Card 1 */}
        <div
          style={{
            width: "30%",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.3s ease", // Transition for the hover effect
            borderBottom: "6px solid red", // Red bottom border
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "translateY(-10px)"; // Lift on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "translateY(0)"; // Reset on hover out
          }}
        >
          <img
            src="https://cdn.pixabay.com/photo/2016/09/07/14/18/bugatti-1651718_1280.png"
            alt="Card 1 Image"
            style={{
              width: "100px",
              height: "100px",
              margin: "0 auto",
              display: "block",
            }}
          />
          <h3 style={{ textAlign: "center", marginTop: "0px" }}>
            Quality Brands
          </h3>
          <p style={{ textAlign: "left", fontSize: "14px", color: "#666" }}>
            At The Detailing Mafia, we aim for 3 A's - Affordable, Accessible,
            and Advanced car care services. We use World Class Products in{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              Car Detailing
            </span>{" "}
            . Our products excel in providing superior protection & ultimate
            results for your vehicle.
          </p>
        </div>

        {/* Card 2 */}
        <div
          style={{
            width: "30%",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.3s ease", // Transition for the hover effect
            borderBottom: "6px solid red", // Red bottom border
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "translateY(-10px)"; // Lift on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "translateY(0)"; // Reset on hover out
          }}
        >
          <img
            src="https://cdn.pixabay.com/photo/2024/05/31/19/59/ai-generated-8801171_1280.png"
            alt="Card 2 Image"
            style={{
              width: "100px",
              height: "100px",
              margin: "0 auto",
              display: "block",
            }}
          />
          <h3 style={{ textAlign: "center", marginTop: "0px" }}>
            Professional Staff
          </h3>
          <p style={{ textAlign: "center", fontSize: "14px", color: "#666" }}>
            We have Certified "Trained Professional Staff" with 16 years of
            experience in car detailing. Our team always goes the extra mile to
            provide your car with the finest detailing services.
          </p>
        </div>

        {/* Card 3 */}
        <div
          style={{
            width: "30%",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.3s ease", // Transition for the hover effect
            borderBottom: "6px solid red", // Red bottom border
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "translateY(-10px)"; // Lift on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "translateY(0)"; // Reset on hover out
          }}
        >
          <img
            src="https://cdn.pixabay.com/photo/2024/06/04/13/13/dolphin-8808789_1280.png"
            alt="Card 3 Image"
            style={{
              width: "100px",
              height: "100px",
              margin: "0 auto",
              display: "block",
            }}
          />
          <h3 style={{ textAlign: "center", marginTop: "20px" }}>
            Wide Range of Services
          </h3>
          <p style={{ textAlign: "center", fontSize: "14px", color: "#666" }}>
            Our 'Made to Measure' services are specifically designed to fulfill
            your expectations. We provide you all car care services like{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              Ceramic Wash, Paint Protection Film{" "}
            </span>
            .Spray on PPF, and more under one umbrella.
          </p>
        </div>
      </div>
      <Footer />
      {/* Footer Section */}
      <div
        style={{
          backgroundColor: "#ff3a00",
          textAlign: "center",
          color: "white",
          fontSize: "13px",
          position: "fixed",
          bottom: 0,
          width: "100%",
        }}
      >
        <p>Get Started - +91-80-100-44000</p>
      </div>
    </div>
  );
}

export default AboutUs;
