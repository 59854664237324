import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

function InventoryUpdates() {
  const [updates, setUpdates] = useState([]);
  const [error, setError] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [updatesPerPage] = useState(5); // Number of updates to show per page

  // Single search term for filtering multiple fields
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchUpdates = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setError("No token found. Please log in.");
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("x-auth-token", token);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          "http://api.mrmechanic.co:5000/api/inventory/updates",
          requestOptions
        );
        const result = await response.json();

        // Ensure result is an array before setting it in state
        if (Array.isArray(result)) {
          setUpdates(result);
          setError(null); // Clear any previous errors
        } else {
          console.error("Expected an array but got:", result);
          setUpdates([]); // Set updates to empty array to prevent map error
          setError("Unexpected data format from server."); // Set error message
        }
      } catch (error) {
        console.error("Error fetching updates:", error);
        setUpdates([]); // Set to empty array in case of error
        setError("Error fetching updates."); // Set error message
      }
    };

    fetchUpdates();
  }, []);

  // Filter updates based on search term (for multiple fields)
  const filteredUpdates = updates.filter((update) => {
    const { itemName, company, category, updatedBy } = update;

    return (
      itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.toLowerCase().includes(searchTerm.toLowerCase()) ||
      category.toLowerCase().includes(searchTerm.toLowerCase()) ||
      updatedBy.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Pagination Logic
  const indexOfLastUpdate = currentPage * updatesPerPage;
  const indexOfFirstUpdate = indexOfLastUpdate - updatesPerPage;
  const currentUpdates = filteredUpdates.slice(
    indexOfFirstUpdate,
    indexOfLastUpdate
  );

  const totalPages = Math.ceil(filteredUpdates.length / updatesPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const dashboardStyle = {
    display: "flex",
    flexDirection: "row",
  };

  const contentStyle = {
    marginLeft: "300px",
    padding: "20px",
    flex: 1,
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  };

  const thStyle = {
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "10px",
    textAlign: "left",
  };

  const tdStyle = {
    padding: "10px",
    border: "1px solid #ddd",
  };

  return (
    <div>
      <Navbar />
      <div style={dashboardStyle}>
        <Sidebar />
        <div style={contentStyle}>
          <h2>Inventory Updates</h2>

          {/* Search Filter */}
          <div>
            <input
              type="text"
              placeholder="Search by name, company, category, updated by"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                padding: "10px",
                width: "300px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                marginBottom: "20px",
              }}
            />
          </div>

          {/* Table */}
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>Item Name</th>
                <th style={thStyle}>Company</th>
                <th style={thStyle}>Category</th>
                <th style={thStyle}>Subcategories</th>
                <th style={thStyle}>Updated By</th>
                <th style={thStyle}>Update Details</th>
                <th style={thStyle}>Update Date-Time</th>
              </tr>
            </thead>
            <tbody>
              {currentUpdates.length > 0 ? (
                currentUpdates.map((update, index) => (
                  <tr key={index}>
                    <td style={tdStyle}>{update.itemName}</td>
                    <td style={tdStyle}>{update.company}</td>
                    <td style={tdStyle}>{update.category}</td>
                    <td style={tdStyle}>
                      {update.subCategories.map((sub, i) => (
                        <div key={i}>{sub}</div>
                      ))}
                    </td>
                    <td style={tdStyle}>{update.updatedBy}</td>
                    <td style={tdStyle}>{update.updateDetails}</td>
                    <td style={tdStyle}>
                      {new Date(update.updateDate).toLocaleString()}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No updates available</td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                style={{
                  padding: "10px",
                  margin: "5px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  backgroundColor:
                    currentPage === index + 1 ? "#4CAF50" : "#fff",
                  color: currentPage === index + 1 ? "#fff" : "#000",
                  cursor: "pointer",
                }}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryUpdates;
