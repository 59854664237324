import React from "react";
import { Link } from "react-router-dom";
import { FaBars, FaPlus, FaUserCircle, FaGlobe } from "react-icons/fa"; // Use FontAwesome icons

const Navbar = () => {
  const navbarStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#ffffff", // White background like the image
    padding: "10px 20px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Light shadow for navbar
  };

  const leftContainerStyle = {
    display: "flex",
    alignItems: "center",
  };

  const rightContainerStyle = {
    display: "flex",
    alignItems: "center",
  };

  const menuIconStyle = {
    fontSize: "24px",
    marginRight: "20px",
    cursor: "pointer",
  };

  const buttonStyle = {
    backgroundColor: "#F7F7F8", // Light background for button
    border: "none",
    padding: "10px 15px",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginRight: "20px",
    fontWeight: "bold",
  };

  const posButtonStyle = {
    backgroundColor: "#ffffff", // White background for POS button
    border: "1px solid #D3D3D3", // Light grey border
    padding: "10px 15px",
    borderRadius: "5px",
    cursor: "pointer",
    marginRight: "20px",
    fontWeight: "bold",
  };

  const iconStyle = {
    fontSize: "24px",
    color: "#000000",
    cursor: "pointer",
    marginRight: "20px",
  };

  return (
    <nav style={navbarStyle}>
      <div style={leftContainerStyle}>
        <FaBars style={menuIconStyle} /> {/* Menu icon */}
        <button style={buttonStyle}>
          <FaPlus style={{ marginRight: "10px" }} />
          Add new
        </button>
      </div>
      <div style={rightContainerStyle}>
        <button style={posButtonStyle}>POS</button>
        <FaGlobe style={iconStyle} /> {/* Global icon */}
        <FaUserCircle style={iconStyle} /> {/* Profile icon */}
      </div>
    </nav>
  );
};

export default Navbar;
