import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AuthContext, { AuthProvider } from "./context/AuthContext";
import Dashboard from "./components/Dashboard";
import Main from "./pages/Main"; // Import the Main screen
import Login from "./components/Login";
import Register from "./components/Register";
import AboutUs from "./pages/AboutUs";
import Users from "./pages/Users";
import PartnerList from "./pages/PartnerList";
import Inventory from "./pages/Inventory";
import InventoryUpdates from "./pages/InventoryUpdates";
import StockTransfer from "./pages/StockTransfer";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Route for Main (accessible without login) */}
          <Route path="/" element={<Main />} />

          {/* Public Routes for Login and Register */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/aboutUs" element={<AboutUs />} />

          {/* Protected Route for Dashboard (or other authenticated components) */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                roles={["superadmin", "admin", "partner", "customer"]}
              >
                <Dashboard /> {/* Authenticated screen */}
              </PrivateRoute>
            }
          />

          {/* Protected Route for Users (Only visible to superadmin) */}
          <Route
            path="/users"
            element={
              <PrivateRoute roles={["superadmin"]}>
                <Users />
              </PrivateRoute>
            }
          />

          {/* Protected Route for PartnerList (Only visible to admin) */}
          <Route
            path="/partnerList"
            element={
              <PrivateRoute roles={["admin"]}>
                <PartnerList />
              </PrivateRoute>
            }
          />

          {/* Protected Route for Inventory (Only visible to superadmin and admin) */}
          <Route
            path="/inventory"
            element={
              <PrivateRoute roles={["superadmin", "admin"]}>
                <Inventory />
              </PrivateRoute>
            }
          />
          {/* Protected Route for Stock transfer (Only visible to superadmin and admin) */}
          <Route
            path="/stock-transfer"
            element={
              <PrivateRoute roles={["superadmin", "admin"]}>
                <StockTransfer />
              </PrivateRoute>
            }
          />
          {/* Protected Route for InventoryUpdates (Only visible to superadmin) */}
          <Route
            path="/inventory-updates"
            element={
              <PrivateRoute roles={["superadmin"]}>
                <InventoryUpdates />
              </PrivateRoute>
            }
          />

          {/* Redirect any unmatched route to Main */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

const PrivateRoute = ({ children, roles }) => {
  const { user } = React.useContext(AuthContext);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return roles.includes(user.role) ? children : <Navigate to="/" />;
};

export default App;
