import React, { useEffect, useState } from "react";
import FlipPart from "../components/MainPage/FlipPart";
import CrousalPart from "../components/MainPage/CrousalPart";
import WhyUs from "../components/MainPage/WhyUs";
import Network from "../components/MainPage/Network";
import Footer from "../components/MainPage/Footer";
import { useNavigate } from "react-router-dom";
import MainHeader from "../components/MainHeader";

function Main() {
  const [showStaticText, setShowStaticText] = useState(false);
  const [showAnimatedContent, setShowAnimatedContent] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0); // State for current slide
  const navigate = useNavigate(); // Hook to access navigation

  const handleLoginClick = () => {
    navigate("/login"); // Navigate to the /login route
  };
  const images = [
    "https://images.unsplash.com/photo-1498887960847-2a5e46312788?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y2FyJTIwYXNzZXN0c3xlbnwwfHwwfHx8MA%3D%3D",
    "https://images.unsplash.com/photo-1490902931801-d6f80ca94fe4?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Y2FyJTIwYXNzZXN0c3xlbnwwfHwwfHx8MA%3D%3D",
    "https://images.unsplash.com/photo-1459603677915-a62079ffd002?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGNhciUyMGFzc2VzdHN8ZW58MHx8MHx8fDA%3D",
    "https://images.unsplash.com/photo-1517026575980-3e1e2dedeab4?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Y2FyJTIwYXNzZXN0c3xlbnwwfHwwfHx8MA%3D%3D",
    "https://images.unsplash.com/photo-1469285994282-454ceb49e63c?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGNhciUyMGFzc2VzdHN8ZW58MHx8MHx8fDA%3D",
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setShowStaticText(scrollY > 200);
      setShowAnimatedContent(scrollY > 400);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [images.length]);

  const bannerStyle = {
    position: "relative",
    textAlign: "center",
    overflow: "hidden", // Prevent overflow
  };

  const bannerVideoStyle = {
    width: "100%",
    height: "auto", // Change height to auto
    maxHeight: "500px", // Limit the max height
    objectFit: "cover",
    display: "block", // Prevent inline spacing
  };

  const floatingLeftStyle = {
    position: "fixed",
    left: "0",
    top: "40%",
    transform: "translateY(-50%)",
    backgroundColor: "#ff3a00",
    color: "white",
    padding: "10px",
  };

  const floatingRightStyle = {
    position: "fixed",
    right: "10px",
    bottom: "40px",
  };

  const floatingWhatsAppStyle = {
    color: "#25D366",
    fontSize: "30px",
    textDecoration: "none",
  };

  const staticTextStyle = {
    textAlign: "center",
    opacity: showStaticText ? 1 : 0,
    transition: "opacity 0.5s ease",
    padding: "20px",
  };

  const animatedSectionStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "50px",
    position: "relative",
  };

  const carouselContainerStyle = {
    display: "flex",
    overflow: "hidden",
    width: "100%",
    maxHeight: "400px",
  };

  const carouselInnerStyle = {
    display: "flex",
    transition: "transform 0.5s ease",
    transform: `translateX(-${currentSlide * 100}%)`,
  };

  const carouselImageStyle = {
    width: "100%",
    objectFit: "cover",
    flexShrink: 0,
    maxHeight: "400px",
  };

  const indicatorsStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  };

  const indicatorStyle = {
    height: "10px",
    width: "10px",
    margin: "0 5px",
    borderRadius: "50%",
    backgroundColor: "#ddd",
    cursor: "pointer",
  };

  const activeIndicatorStyle = {
    backgroundColor: "#ff3a00",
  };

  const carStyle = {
    flex: 1,
    opacity: showAnimatedContent ? 1 : 0,
    transition: "opacity 0.7s ease",
  };

  const carTextStyle = {
    flex: 1,
    opacity: showAnimatedContent ? 1 : 0,
    transition: "opacity 0.7s ease",
  };

  const footerCtaStyle = {
    position: "fixed",
    bottom: "0",
    width: "100%",
    backgroundColor: "#ff3a00",
    color: "white",
    textAlign: "center",
    padding: "10px 0",
  };

  return (
    <div>
      {/* Header Section */}
      <MainHeader />

      {/* Main Banner Section */}
      <section style={bannerStyle}>
        <video style={bannerVideoStyle} autoPlay loop muted playsInline>
          <source
            src="https://videos.pexels.com/video-files/2053100/2053100-sd_640_360_30fps.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </section>

      {/* Static Text Section */}
      <section style={staticTextStyle}>
        <h1>Detailing is not a skill, it's an attitude.</h1>
      </section>

      {/* Animated Car and Text Section */}
      <section style={animatedSectionStyle}>
        <div style={carStyle}>
          <img
            src="https://images.unsplash.com/photo-1485463611174-f302f6a5c1c9?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Y2FyfGVufDB8fDB8fHww"
            alt="Car"
          />
        </div>
        <div style={carTextStyle}>
          <p>
            Welcome to The Detailing Mafia: One-Stop Solution to All Your Car
            Care Problems. “Perfection is in the Detail”, this is what we
            believe in. We are called “The King of the Detailing World” by the
            Automotive Detailing Industry. Our skilled and trained personnel
            have turned ordinary looking cars into Fabulous-looking Ones. With
            all hands-on decks, we strive to impress. Our hard work and long
            hours have turned many tough-stained Cars into mesmerizing beauties.
            We pat our backs for having the Perfect Eye for Car Detailing. We
            imbibe a wide range of car care products, like the Clarity Coat,
            Ceramic Coating, Paint Protection Film, and so on, that can be
            customized as per your vehicle’s requirements.
          </p>
          <div style={{ textAlign: "right" }}>
            <button
              style={{
                backgroundColor: "#ff3a00",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                marginTop: "20px",
                borderRadius: "5px", // Added border radius
              }}
            >
              Know More
            </button>
          </div>
        </div>
      </section>

      {/* Carousel Section */}
      <div>
        <div style={carouselContainerStyle}>
          <div style={carouselInnerStyle}>
            {images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`Slide ${index}`}
                style={carouselImageStyle}
              />
            ))}
          </div>
        </div>
        <div style={indicatorsStyle}>
          {images.map((_, index) => (
            <div
              key={index}
              style={{
                ...indicatorStyle,
                ...(currentSlide === index ? activeIndicatorStyle : {}),
              }}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
      </div>

      {/* second static section */}

      <section
        style={{
          textAlign: "left",
          opacity: showStaticText ? 1 : 0,
          transition: "opacity 0.5s ease",
          padding: "20px",
        }}
      >
        <h1>What do we strive for?</h1>
        <h3
          style={{
            color: "red",
          }}
        >
          Detailing at it's Finest!
        </h3>
      </section>

      {/* Animated Car and Text Section */}
      <section style={animatedSectionStyle}>
        <div style={carTextStyle}>
          <p>
            At The Detailing Mafia, we are determined in getting the car to look
            like, ‘what you thought was Good’ to ‘Is this really my Car?’ When
            delivering out top-notch car detailing services we do not compromise
            on quality and offer our professional opinion to what the customer
            should get. Just drop off your car at our outlet and wait for our
            trained technicians to perform the Magic. Our trained staff takes
            care of every tiny detail and does away with all the minute scars to
            give that stunning look to your vehicle. Get ready to be astonished
            by the results of our car detailing services. Give your Neighbours
            Something to Talk About!
          </p>
          <div style={{ textAlign: "left" }}>
            <p
              style={{
                fontWeight: "500",
              }}
            >
              Give your Neighbours Something to Talk About!
            </p>
          </div>
        </div>

        <div style={carStyle}>
          <img
            src="https://images.unsplash.com/photo-1449965408869-eaa3f722e40d?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGNhciUyMGFzc2VzdHN8ZW58MHx8MHx8fDA%3D"
            alt="Car"
          />
        </div>
      </section>
      <FlipPart />
      <CrousalPart />
      <WhyUs />
      <Network />
      <Footer />
      {/* Floating Sidebars */}
      <div style={floatingLeftStyle}>
        <a
          href="tel:+911001004400"
          style={{ color: "white", textDecoration: "none" }}
        >
          Call Us Today!
        </a>
      </div>
      <div style={floatingRightStyle}>
        <a href="https://wa.me/+911001004400" style={floatingWhatsAppStyle}>
          <i className="fab fa-whatsapp"></i>
        </a>
      </div>

      {/* Footer Call to Action */}
      <footer style={footerCtaStyle}>
        <div className="cta-content">
          <span>Get Started - +91-80-100-44000</span>
        </div>
      </footer>
    </div>
  );
}

export default Main;
