import React from "react";

function Footer() {
  return (
    <div
      style={{
        backgroundColor: "#181818",
        color: "#fff",
        padding: "20px 0",
        fontFamily: "Arial, sans-serif",
        marginBottom: "40px"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "0 50px",
        }}
      >
        {/* Left Section - Logo and Contact Info */}
        <div style={{ flex: 1, textAlign: "left" }}>
          <img
            src="https://images.unsplash.com/photo-1473042904451-00171c69419d?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGNhcnN8ZW58MHx8MHx8fDA%3D" // Replace with the actual path to your logo
            alt="Logo"
            style={{ width: "150px", marginBottom: "15px" }} // Adjust size as needed
          />
          <p style={{ marginBottom: "10px" }}>
            D-120, SECTOR-63, Noida, Gautambuddha Nagar, Uttar Pradesh, 201307
            India.
          </p>
          <p style={{ marginBottom: "5px" }}>+91-80-100-44000</p>
          <p style={{ marginBottom: "5px" }}>+91-92-894-44440</p>
          <p style={{ marginBottom: "5px" }}>info@thedetailingmafia.com</p>
          <p style={{ marginBottom: "15px" }}>hello@manmachinecarcare.com</p>

          {/* Social Media Icons */}
          <div style={{ marginTop: "10px" }}>
            <a
              href="https://www.facebook.com"
              style={{ marginRight: "10px", color: "#fff" }}
            >
              Facebook
            </a>
            <a
              href="https://www.twitter.com"
              style={{ marginRight: "10px", color: "#fff" }}
            >
              Twitter
            </a>
            <a
              href="https://www.instagram.com"
              style={{ marginRight: "10px", color: "#fff" }}
            >
              Instagram
            </a>
            <a
              href="https://www.linkedin.com"
              style={{ marginRight: "10px", color: "#fff" }}
            >
              LinkedIn
            </a>
          </div>
        </div>

        {/* Center Section - Services and Packages */}
        <div style={{ flex: 1 }}>
          <h3 style={{ color: "#f15822", marginBottom: "15px" }}>
            OUR SERVICE
          </h3>
          <ul style={{ listStyleType: "none", padding: 0, lineHeight: "2" }}>
            <li>Ceramic Coating</li>
            <li>Labocosmetica</li>
            <li>Paint Protection Film</li>
            <li>Glass Film</li>
            <li>Windshield Protection</li>
            <li>Ceramic Wash</li>
            <li>Steam Wash</li>
          </ul>
        </div>

        {/* Right Section - Mafia Packages */}
        <div style={{ flex: 1 }}>
          <h3 style={{ color: "#f15822", marginBottom: "15px" }}>
            MAFIA PACKAGES
          </h3>
          <ul style={{ listStyleType: "none", padding: 0, lineHeight: "2" }}>
            <li>The Bull</li>
            <li>The Iceman</li>
            <li>The Viper</li>
            <li>The Wolf</li>
            <li>The Shark</li>
            <li>The Al Capone</li>
          </ul>
        </div>

        {/* Special Services and Franchise */}
        <div style={{ flex: 1 }}>
          <h3 style={{ color: "#f15822", marginBottom: "15px" }}>FRANCHISE</h3>
          <ul style={{ listStyleType: "none", padding: 0, lineHeight: "2" }}>
            <li>Apply for Franchise</li>
            <li>Become an Installer</li>
            <li>Rent Your Property</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
