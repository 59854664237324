import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CrousalPart = () => {
  const [showContent, setShowContent] = useState(true);
  let lastScrollTop = 0;

  const arrowStyle = {
    color: "white",
    fontSize: "24px",
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
  };

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <div style={arrowStyle}>→</div>,
    prevArrow: <div style={arrowStyle}>←</div>,
  };

  const styles = {
    carouselContainer: {
      backgroundColor: "red",
      padding: "20px",
      textAlign: "center",
      position: "relative",
    },
    headerSection: {
      fontSize: "50px",
      marginBottom: "100px",
      opacity: showContent ? 1 : 0, // Show or hide based on scroll
      transition: "opacity 0.6s ease-in-out",
    },
    headerH1: {
      fontSize: "40px",
      color: "white",
      textTransform: "uppercase",
    },
    headerH2: {
      fontSize: "20px",
      color: "white",
      marginTop: "-20px",
      textTransform: "uppercase",
    },
    slider: {
      margin: "-60px 0",
    },
    carouselImage: {
      width: "100%",
      maxHeight: "400px",
      objectFit: "cover",
    },
    buttonSection: {
      marginTop: "100px",
      transition: "opacity 0.3s",
      opacity: showContent ? 1 : 0, // Show or hide based on scroll
    },
    carouselButton: {
      backgroundColor: "#fff",
      borderRadius: "5px",
      color: "black",
      border: "none",
      padding: "10px 20px",
      margin: "0 10px",
      cursor: "pointer",
      fontSize: "20px",
      fontWeight: "700",
      textTransform: "uppercase",
      transition: "background-color 0.3s ease",
    },
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop) {
      setShowContent(true); // Show content when scrolling down
    } else {
      setShowContent(false); // Hide content when scrolling up
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // Reset lastScrollTop
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up the event listener
    };
  }, []);

  return (
    <div style={styles.carouselContainer}>
      <div style={styles.headerSection}>
        <h1 style={styles.headerH1}>Ceramic Coating</h1>
        <h2 style={styles.headerH2}>Precision With Style</h2>
      </div>
      <Slider {...settings} style={styles.slider}>
        <div>
          <img
            src="https://plus.unsplash.com/premium_photo-1664304752635-3e0d8d8185e3?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y2Fyc3xlbnwwfHwwfHx8MA%3D%3D"
            alt="First Slide"
            style={styles.carouselImage}
          />
        </div>
        <div>
          <img
            src="https://plus.unsplash.com/premium_photo-1664303847960-586318f59035?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y2Fyc3xlbnwwfHwwfHx8MA%3D%3D"
            alt="Second Slide"
            style={styles.carouselImage}
          />
        </div>
        <div>
          <img
            src="https://images.unsplash.com/photo-1488510665058-33ce2e6ab561?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGNhcnN8ZW58MHx8MHx8fDA%3D"
            alt="Third Slide"
            style={styles.carouselImage}
          />
        </div>
      </Slider>
      <div style={styles.buttonSection}>
        <button style={styles.carouselButton}>About Us</button>
        <button style={styles.carouselButton}>Our Services</button>
        <button style={styles.carouselButton}>Contact Us</button>
      </div>
    </div>
  );
};

export default CrousalPart;
