import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  FaUsers,
  FaBox,
  FaClipboardList,
  FaChartLine,
  FaCogs,
  FaClipboard,
} from "react-icons/fa"; // Icons similar to the ones in the image
import AuthContext from "../context/AuthContext"; // Import AuthContext

const Sidebar = () => {
  const { user } = useContext(AuthContext); // Access user from AuthContext

  const sidebarStyle = {
    height: "100vh",
    width: "250px",
    backgroundColor: "#1a1e24",
    color: "#ffffff",
    padding: "20px",
    position: "fixed",
  };

  const logoStyle = {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  };

  const listItemStyle = {
    listStyleType: "none",
    padding: "10px",
    borderRadius: "8px",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  const activeItemStyle = {
    backgroundColor: "#3b4b61",
  };

  const iconStyle = {
    marginRight: "10px",
  };

  const linkStyle = {
    color: "#ffffff",
    textDecoration: "none",
    fontSize: "16px",
  };

  return (
    <div style={sidebarStyle}>
      <div style={logoStyle}>
        <img
          src="https://cdn-icons-png.flaticon.com/512/733/733579.png"
          alt="logo"
          style={{ height: "50px" }}
        />
      </div>
      <ul>
        <li style={{ ...listItemStyle, ...activeItemStyle }}>
          <FaChartLine style={iconStyle} />
          <Link to="/dashboard" style={linkStyle}>
            Dashboard
          </Link>
        </li>

        {/* Conditionally render the Users link for superadmin */}
        {user?.role === "superadmin" && (
          <li style={listItemStyle}>
            <FaUsers style={iconStyle} />
            <Link to="/users" style={linkStyle}>
              Users
            </Link>
          </li>
        )}

        {/* Conditionally render the Partner List link for admin */}
        {user?.role === "admin" && (
          <li style={listItemStyle}>
            <FaUsers style={iconStyle} />
            <Link to="/partnerList" style={linkStyle}>
              Partners List
            </Link>
          </li>
        )}

        <li style={listItemStyle}>
          <FaUsers style={iconStyle} />
          <Link to="/inventory" style={linkStyle}>
            Inventory
          </Link>
        </li>
        <li style={listItemStyle}>
          <FaUsers style={iconStyle} />
          <Link to="/stock-transfer" style={linkStyle}>
            Stock Transfer
          </Link>
        </li>

        {user?.role === "superadmin" && (
          <li style={listItemStyle}>
            <FaUsers style={iconStyle} />
            <Link to="/inventory-updates" style={linkStyle}>
              Inventory updates
            </Link>
          </li>
        )}

        {/* <li style={listItemStyle}>
          <FaBox style={iconStyle} />
          <Link to="/purchase-orders" style={linkStyle}>
            Purchase Orders
          </Link>
        </li>
        <li style={listItemStyle}>
          <FaClipboardList style={iconStyle} />
          <Link to="/sales" style={linkStyle}>
            Sales
          </Link>
        </li>
        <li style={listItemStyle}>
          <FaCogs style={iconStyle} />
          <Link to="/service-job" style={linkStyle}>
            Service Jobs
          </Link>
        </li>
        <li style={listItemStyle}>
          <FaClipboard style={iconStyle} />
          <Link to="/quotations" style={linkStyle}>
            Quotations
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default Sidebar;
