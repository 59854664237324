import React, { useState, useContext } from "react";
import AuthContext from "../context/AuthContext";

const Register = ({ onClose }) => {
  const { register } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("customer");
  const [company, setCompany] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [registrationError, setRegistrationError] = useState(""); // New error state

  const isFormValid = name && email && password && role;

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    } else {
      setEmailError("");
    }

    try {
      await register(name, email, password, role, company);
      setPopupVisible(true);
      setName(""); // Reset name
      setEmail(""); // Reset email
      setPassword(""); // Reset password
      setCompany(""); // Reset company
      onClose();
      setTimeout(() => {
        setPopupVisible(false);
      }, 2000);
    } catch (error) {
      console.error("Error during registration:", error);
      setRegistrationError("Registration failed. Please try again."); // Set registration error
    }
  };

  // Inline styles
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
    },
    card: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      width: "100%",
      maxWidth: "500px",
      boxSizing: "border-box",
      transition: "all 0.3s ease",
    },
    cardHover: {
      boxShadow: "0 6px 20px rgba(0, 0, 0, 0.15)",
    },
    formGroup: {
      marginBottom: "15px",
    },
    input: {
      width: "100%",
      padding: "10px",
      fontSize: "16px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      transition: "border-color 0.3s ease",
      boxSizing: "border-box",
      outline: "none",
    },
    label: {
      fontWeight: "600",
      display: "block",
      marginBottom: "5px",
    },
    inputFocus: {
      borderColor: "#1ca8cb",
    },
    button: {
      width: "100%",
      padding: "12px",
      backgroundColor: "#1ca8cb",
      color: "#fff",
      fontSize: "16px",
      border: "none",
      borderRadius: "5px",
      cursor: isFormValid ? "pointer" : "not-allowed",
      opacity: isFormValid ? 1 : 0.6,
      transition: "background-color 0.3s ease",
    },
    buttonHover: {
      backgroundColor: "#178aa9",
    },
    popup: {
      position: "fixed",
      top: "20%",
      left: "50%",
      transform: "translateX(-50%)",
      padding: "20px",
      backgroundColor: "#28a745",
      color: "#fff",
      borderRadius: "5px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
      zIndex: 1000,
    },
    error: {
      color: "red",
      marginTop: "5px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Register</h2>
        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label style={styles.label}>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={styles.input}
              placeholder="Enter your name"
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={styles.input}
              onFocus={(e) =>
                (e.target.style.borderColor = styles.inputFocus.borderColor)
              }
              onBlur={(e) => (e.target.style.borderColor = "#ccc")}
              placeholder="Enter your email"
              autoComplete="new-email" // Use a unique value
            />
            {emailError && <div style={styles.error}>{emailError}</div>}{" "}
            {/* Display error message */}
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={styles.input}
              onFocus={(e) =>
                (e.target.style.borderColor = styles.inputFocus.borderColor)
              }
              onBlur={(e) => (e.target.style.borderColor = "#ccc")}
              placeholder="Enter your password"
              autoComplete="new-password" // Use a unique value
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Role</label>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              style={styles.input}
            >
              <option value="customer">Customer</option>
              <option value="partner">Partner</option>
              <option value="admin">Admin</option>
              <option value="superadmin">Superadmin</option>
            </select>
          </div>
          {(role === "partner" || role === "admin") && (
            <div style={styles.formGroup}>
              <label style={styles.label}>Company</label>
              <input
                type="text"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                style={styles.input}
                placeholder="Enter your company name" // Placeholder for company
              />
            </div>
          )}
          <button type="submit" style={styles.button} disabled={!isFormValid}>
            Register
          </button>
          {registrationError && (
            <div style={styles.error}>{registrationError}</div>
          )}{" "}
          {/* Display registration error */}
        </form>
        {popupVisible && (
          <div style={styles.popup}>Registration successful!</div>
        )}
        {registrationError && (
          <div style={styles.error}>{registrationError}</div>
        )}
      </div>
    </div>
  );
};

export default Register;
