import React from "react";

function Network() {
  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "450px",
    backgroundImage:
      "url('https://plus.unsplash.com/premium_photo-1664303619183-6effc8cf1c01?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8Y2Fyc3xlbnwwfHwwfHx8MA%3D%3D')", // Replace with your image path
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark overlay for better text visibility
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  };

  const joinTextStyle = {
    fontSize: "2rem",
    marginBottom: "0",
  };

  const networkTextStyle = {
    fontSize: "4rem",
    color: "#f05332", // Orange color for 'NETWORK' text
  };

  const buttonContainerStyle = {
    marginTop: "20px",
  };

  const buttonStyle = {
    backgroundColor: "transparent",
    border: "2px solid #f05332",
    color: "white",
    padding: "10px 20px",
    fontSize: "1rem",
    margin: "0 10px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    width: "200px",
  };

  const buttonHoverStyle = {
    backgroundColor: "#f05332",
  };

  return (
    <div style={containerStyle}>
      <div style={overlayStyle}>
        <h1 style={joinTextStyle}>JOIN OUR</h1>
        <h1 style={networkTextStyle}>NETWORK</h1>
        <div style={buttonContainerStyle}>
          <button
            style={{ ...buttonStyle }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#f05332")}
            onMouseLeave={(e) =>
              (e.target.style.backgroundColor = "transparent")
            }
          >
            BECOME AN INSTALLER
          </button>
          <button
            style={{ ...buttonStyle }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#f05332")}
            onMouseLeave={(e) =>
              (e.target.style.backgroundColor = "transparent")
            }
          >
            BECOME A FRANCHISE
          </button>
        </div>
      </div>
    </div>
  );
}

export default Network;
