import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

function StockTransfer() {
  const [inventories, setInventories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyFilter, setCompanyFilter] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedUnits, setSelectedUnits] = useState([]);

  // Fetch inventories from the backend API
  useEffect(() => {
    const headers = new Headers({
      "x-auth-token": localStorage.getItem("token"),
      "Content-Type": "application/json",
    });

    fetch("http://api.mrmechanic.co:5000/api/inventory", {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((data) => setInventories(Array.isArray(data) ? data : []))
      .catch((error) => console.error("Error fetching inventories:", error));
  }, []);

  const filteredInventories = inventories.filter((item) => {
    const matchesSearchTerm = item.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCompanyFilter = companyFilter
      ? item.company === companyFilter
      : true;
    return matchesSearchTerm && matchesCompanyFilter;
  });

  const companies = [...new Set(inventories.map((item) => item.company))];

  // Handle category and subcategory selection
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedSubCategory(""); // Reset subcategory when category changes
  };

  const handleSubCategorySelect = (subCategory) =>
    setSelectedSubCategory(subCategory);

  // Handle unit selection (multiple units)
  const handleUnitSelect = (unitId) => {
    setSelectedUnits((prevUnits) =>
      prevUnits.includes(unitId)
        ? prevUnits.filter((id) => id !== unitId)
        : [...prevUnits, unitId]
    );
  };

  // Open modal and set selected item
  const handleTransferClick = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setSelectedCategory("");
    setSelectedSubCategory("");
    setSelectedUnits([]);
  };

  return (
    <div>
      <Navbar />
      <div style={styles.container}>
        <Sidebar />
        <div style={styles.mainContent}>
          <h2>Stock Transfer</h2>

          {/* Search bar and company filter */}
          <div style={{ marginBottom: "20px", display: "flex", gap: "10px" }}>
            <input
              type="text"
              placeholder="Search by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={styles.input}
            />
            <select
              value={companyFilter}
              onChange={(e) => setCompanyFilter(e.target.value)}
              style={styles.select}
            >
              <option value="">All Companies</option>
              {companies.map((company) => (
                <option key={company} value={company}>
                  {company}
                </option>
              ))}
            </select>
          </div>

          {/* Inventory Table */}
          <table style={styles.table}>
            <thead>
              <tr style={styles.tableHeader}>
                <th style={styles.tableCell}>Name</th>
                <th style={styles.tableCell}>Description</th>
                <th style={styles.tableCell}>Category</th>
                <th style={styles.tableCell}>Company</th>
                <th style={styles.tableCell}>Price</th>
                <th style={styles.tableCell}>Quantity</th>
                <th style={styles.tableCell}>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredInventories.length > 0 ? (
                filteredInventories.map((item) => (
                  <tr key={item._id} style={{ textAlign: "center" }}>
                    <td style={styles.tableCell}>{item.name}</td>
                    <td style={styles.tableCell}>{item.description}</td>
                    <td style={styles.tableCell}>{item.category}</td>
                    <td style={styles.tableCell}>{item.company}</td>
                    <td style={styles.tableCell}>
                      {item.subCategories.map((sub) => (
                        <div key={sub.name}>
                          {sub.name}: Rs {sub.price.toFixed(2)}
                        </div>
                      ))}
                    </td>
                    <td style={styles.tableCell}>
                      {item.subCategories.map((sub) => (
                        <div
                          key={sub.name}
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          {sub.name}: {sub.quantity}
                        </div>
                      ))}
                    </td>
                    <td style={styles.tableCell}>
                      <button onClick={() => handleTransferClick(item)}>
                        Transfer
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" style={styles.tableCell}>
                    No inventories found
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Transfer Modal */}
          {showModal && selectedItem && (
            <div style={styles.modalBackdrop}>
              <div style={styles.modalContent}>
                <h3>Transfer Details</h3>
                <p>Name: {selectedItem?.name}</p>
                <p>Description: {selectedItem?.description}</p>

                {/* Category and subcategory selection */}
                <div>
                  <label>Select Category:</label>
                  <select
                    value={selectedCategory}
                    onChange={(e) => handleCategorySelect(e.target.value)}
                  >
                    <option value="">Select Category</option>
                    {selectedItem?.subCategories.map((sub, index) => (
                      <option key={index} value={sub.name}>
                        {sub.name}
                      </option>
                    ))}
                  </select>
                </div>

                {selectedCategory && (
                  <div>
                    <label>Select Subcategory:</label>
                    <select
                      value={selectedSubCategory}
                      onChange={(e) => handleSubCategorySelect(e.target.value)}
                    >
                      <option value="">Select Subcategory</option>
                      {selectedItem?.subCategories
                        .filter((sub) => sub.name === selectedCategory)
                        .map((sub, index) => (
                          <option key={index} value={sub.name}>
                            {sub.name}
                          </option>
                        ))}
                    </select>
                  </div>
                )}

                {/* Unit Selection */}
                {selectedSubCategory && (
                  <div>
                    <label>Select Units:</label>
                    <div>
                      {selectedItem?.subCategories
                        .filter((sub) => sub.name === selectedSubCategory)[0]
                        .units.map((unit) => (
                          <div key={unit._id}>
                            <input
                              type="checkbox"
                              id={`unit-${unit._id}`}
                              onChange={() => handleUnitSelect(unit._id)}
                            />
                            <label htmlFor={`unit-${unit._id}`}>
                              {unit.uniqueId}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                )}

                <div>
                  <button onClick={closeModal}>Close</button>
                  <button onClick={closeModal}>Transfer</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
  },
  mainContent: {
    marginLeft: "300px",
    padding: "20px",
    flex: 1,
  },
  input: {
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    width: "200px",
  },
  select: {
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    width: "150px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
  tableHeader: {
    backgroundColor: "#f2f2f2",
  },
  tableCell: {
    padding: "10px",
    border: "1px solid #ddd",
  },
  modalBackdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    width: "80%",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
  },
};
export default StockTransfer;
