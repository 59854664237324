import React, { useState, useEffect } from "react";

function FlipPart() {
  const [showHeading, setShowHeading] = useState(false); // State to show or hide heading
  let lastScrollTop = 0;

  // Use effect to add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      // Check if scrolling down or up
      if (scrollTop > lastScrollTop) {
        setShowHeading(true); // Show heading when scrolling down
      } else {
        setShowHeading(false); // Hide heading when scrolling up
      }

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For mobile or negative scrolling
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Clean up the event listener
    };
  }, []);

  const containerStyle = {
    textAlign: "center",
    backgroundColor: "#000",
    color: "#fff",
    padding: "40px 0",
  };

  const headingStyle = {
    fontSize: "50px",
    marginBottom: "60px",
    opacity: showHeading ? 1 : 0, // Transition between visible and hidden
    transition: "opacity 0.6s ease-in-out", // Smooth transition
  };

  const highlightTextStyle = {
    color: "#f44336", // red color for 'The Real Magic'
  };

  const flipCardsStyle = {
    display: "flex",
    justifyContent: "center",
    gap: "50px",
    flexWrap: "wrap",
  };

  const flipCardStyle = {
    backgroundColor: "transparent",
    width: "150px",
    height: "200px", // Increased height to accommodate text below
    perspective: "1000px", // For flip effect
  };

  const flipCardInnerStyle = {
    position: "relative",
    width: "100%",
    height: "150px", // Adjusted height for the flip area (icon)
    textAlign: "center",
    transition: "transform 0.6s",
    transformStyle: "preserve-3d",
  };

  const flipCardFrontBackStyle = {
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    borderRadius: "50%", // Circular border for images
  };

  const flipCardFrontStyle = {
    ...flipCardFrontBackStyle,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#333",
  };

  const flipCardFrontImgStyle = {
    width: "80%",
    height: "80%",
    borderRadius: "50%", // Circular images
  };

  const flipCardBackStyle = {
    ...flipCardFrontBackStyle,
    backgroundColor: "#f44336",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "rotateY(180deg)",
  };

  const flipCardBackImgStyle = {
    width: "80%",
    height: "80%",
    borderRadius: "50%", // Circular images
  };

  const textBelowIconStyle = {
    marginTop: "10px",
    fontSize: "0.9rem",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyle}>
      <hr
        style={{
          width: "400px",
          border: "none",
          borderTop: "15px solid red", // Increase thickness here
          margin: "10px auto 10px auto", // Center the line
          fontWeight: "bold",
        }}
      />
      <h2 style={headingStyle}>
        Where <span style={highlightTextStyle}>The Real Magic</span> Happens
      </h2>

      <div style={flipCardsStyle}>
        {[
          {
            src: "https://media.istockphoto.com/id/1182235539/photo/red-map-pointer-isolated-on-white-background.webp?a=1&b=1&s=612x612&w=0&k=20&c=c9XW-vBlgSgwcrhjeWZlZqm1Lo-gqwYpPKlCfbq710E=",
            flippedSrc:
              "https://media.istockphoto.com/id/1200899068/photo/abstract-rating-star-like-positive-feedback.webp?a=1&b=1&s=612x612&w=0&k=20&c=tgdD2m6GBn4G94pwlxQP7IF9Jn3H_nMgBfSq9r4ZyqE=",
            text: "CERAMIC COATING",
          },
          {
            src: "https://media.istockphoto.com/id/1200899068/photo/abstract-rating-star-like-positive-feedback.webp?a=1&b=1&s=612x612&w=0&k=20&c=tgdD2m6GBn4G94pwlxQP7IF9Jn3H_nMgBfSq9r4ZyqE=",
            flippedSrc:
              "https://media.istockphoto.com/id/1395756053/photo/blue-speech-bubble-with-message-and-star-icon.webp?a=1&b=1&s=612x612&w=0&k=20&c=u1gTIOaq4gB8J_NF5FEgCusfE-mv3XKEVkTboccCIts=",
            text: "CLARITY COAT",
          },
          {
            src: "https://media.istockphoto.com/id/1395756053/photo/blue-speech-bubble-with-message-and-star-icon.webp?a=1&b=1&s=612x612&w=0&k=20&c=u1gTIOaq4gB8J_NF5FEgCusfE-mv3XKEVkTboccCIts=",
            flippedSrc:
              "https://media.istockphoto.com/id/1317509990/photo/3d-render-abstract-cartoon-character-flexible-knotted-caucasian-hand-boneless-funny-body-part.webp?a=1&b=1&s=612x612&w=0&k=20&c=7AjA5znDsPjZU5aOoJAUrhoUYize48GXOlDwrLDggKQ=",
            text: "PAINT PROTECTION FILM",
          },
          {
            src: "https://media.istockphoto.com/id/1317509990/photo/3d-render-abstract-cartoon-character-flexible-knotted-caucasian-hand-boneless-funny-body-part.webp?a=1&b=1&s=612x612&w=0&k=20&c=7AjA5znDsPjZU5aOoJAUrhoUYize48GXOlDwrLDggKQ=",
            flippedSrc:
              "https://images.unsplash.com/photo-1660806982611-0a41c0527966?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8M2QlMjBpY29uc3xlbnwwfHwwfHx8MA%3D%3D",
            text: "CAR DENTING PAINTING",
          },
          {
            src: "https://images.unsplash.com/photo-1660806982611-0a41c0527966?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8M2QlMjBpY29uc3xlbnwwfHwwfHx8MA%3D%3D",
            flippedSrc:
              "https://images.unsplash.com/photo-1649180559928-91d16a63bd39?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8M2QlMjBpY29uc3xlbnwwfHwwfHx8MA%3D%3D",
            text: "CAR WASH",
          },
          {
            src: "https://images.unsplash.com/photo-1649180559928-91d16a63bd39?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8M2QlMjBpY29uc3xlbnwwfHwwfHx8MA%3D%3D",
            flippedSrc:
              "https://images.unsplash.com/photo-1660806982611-0a41c0527966?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8M2QlMjBpY29uc3xlbnwwfHwwfHx8MA%3D%3D",
            text: "PAINT CORRECTION",
          },
        ].map((card, index) => (
          <div
            key={index}
            style={flipCardStyle}
            onMouseEnter={(e) => {
              e.currentTarget.querySelector(
                ".flip-card-inner"
              ).style.transform = "rotateY(180deg)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.querySelector(
                ".flip-card-inner"
              ).style.transform = "rotateY(0deg)";
            }}
          >
            <div className="flip-card-inner" style={flipCardInnerStyle}>
              <div style={flipCardFrontStyle}>
                <img src={card.src} alt="" style={flipCardFrontImgStyle} />
              </div>
              <div style={flipCardBackStyle}>
                <img
                  src={card.flippedSrc}
                  alt=""
                  style={flipCardBackImgStyle}
                />
              </div>
            </div>
            <div style={textBelowIconStyle}>{card.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FlipPart;
