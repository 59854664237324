import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";
import Register from "./Register";
import Sidebar from "./Sidebar"; // Import the Sidebar component
import Navbar from "./Navbar"; // Import the Navbar component

const Dashboard = () => {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <div>Loading...</div>; // Show this while user info is loading
  }

  const dashboardStyle = {
    display: "flex",
    flexDirection: "row",
  };

  const contentStyle = {
    marginLeft: "300px", // Default sidebar width
    padding: "20px",
    flex: 1,
  };

  const responsiveStyles = {
    "@media (max-width: 768px)": {
      dashboardStyle: {
        flexDirection: "column",
      },
      contentStyle: {
        marginLeft: "0", // Sidebar will collapse or stack
      },
    },
    "@media (max-width: 1024px)": {
      contentStyle: {
        marginLeft: "200px", // Reduce sidebar width for tablets
      },
    },
    "@media (max-width: 480px)": {
      contentStyle: {
        marginLeft: "0", // Full-width layout for mobile devices
        marginTop: "60px", // Adjust for the navbar
      },
    },
  };

  return (
    <div>
      <Navbar /> {/* Render the Navbar at the top */}
      <div style={{ ...dashboardStyle, ...responsiveStyles.dashboardStyle }}>
        <Sidebar /> {/* Render the Sidebar */}
        <div style={{ ...contentStyle, ...responsiveStyles.contentStyle }}>
          <h1>
            {user.role === "superadmin" && "This is the SuperAdmin Dashboard"}
            {user.role === "admin" && "This is the Admin Dashboard"}
            {user.role === "partner" && "This is the Partner Dashboard"}
            {user.role === "customer" && "This is the Customer Dashboard"}
          </h1>

          {/* Conditionally render features based on role */}
          {user.role === "superadmin" && (
            <div>
              <p>Manage Admins and Partners</p>
              <Register />
            </div>
          )}

          {user.role === "admin" && (
            <div>
              <p>Manage Staff</p>
            </div>
          )}

          {user.role === "partner" && (
            <div>
              <p>View Projects or Sales Data</p>
            </div>
          )}

          {user.role === "customer" && (
            <div>
              <p>View Products or Orders</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
